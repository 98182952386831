<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-envelope"></i>
    <h3 class="box-title text-right">New Criteria</h3>
  </div>
  <div class="box-body">
    <div class="row criteria-form">
   <div class="col-md-4">
      <div class="form-group"><label class="control-label text-left">Name</label><input iservice-id="Name" type="text" class="form-control"></div>
   </div>
   <div class="col-md-4">
      <div class="form-group"><label class="control-label text-left">Description</label><input iservice-id="Description" type="text" class="form-control"></div>
   </div>
   <div class="col-md-12">
      <div class="form-group"><label class="col-12 control-label text-left">Segments That Can Use This Criteria</label></div>
      <div class="form-group">
         <div class="col-md-12">
            <div class="checkbox col-md-3" iservice-id-prefix="'Segment'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="5"> _iService</label></div>
            <div class="checkbox col-md-3" iservice-id-prefix="'Segment'"><label><input iservice-id="Selected" type="checkbox" class="form-check-input" value="2"> 1to1service</label></div>
         </div>
      </div>
   </div>
   <div class="col-md-12">
      <div class="box box-primary">
         <div class="box-header with-border">
            <i class="fa fa-search"></i>
            <h3 class="box-title">Search Parameters For Criteria</h3>
         </div>
         <div class="box-body">
            <section class="search-parameters">
               <fieldset iservice-id-prefix="'Field'">
                  <div class="panel panel-default col-12 bg-gray-light">
                     <div class="row">
                        <div class="col-12 col-md-2">
                           <div class="floating-label bg-white">
                              <select iservice-id="where">
                                 <option>anywhere</option>
                                 <option>start</option>
                                 <option>end</option>
                                 <option>entire</option>
                              </select>
                              <label>Search where</label>
                           </div>
                        </div>
                        <div class="col-12 col-md-4">
                           <div class="floating-label bg-white">
                              <select iservice-id="field">
                                 <option value="all">-- All --</option>
                                 <option value="ID">-- ID --</option>
                                 
                              </select>
                              <label>In field</label>
                           </div>
                        </div>
                        <div class="col-12 col-md-6 search-field">
                           <div class="floating-label bg-white"><input iservice-id="text"><label>Search for</label></div>
                           <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                        </div>
                        <button type="button" class="btn btn-link visible-xs-block visible-sm-block" style="text-align: left; display: none;">Or</button>
                     </div>
                  </div>
                  <div style="display: none;"><button type="button" class="btn btn-link">And</button></div>
               </fieldset>
               <div class="row"><button class="btn btn-lg btn-link" type="button"><i class="fa fa-plus-circle text-light-blue"></i> Add another search parameter </button></div>
            </section>
         </div>
      </div>
   </div>
   <div class="col-md-12">
      <div class="row">
         <div class="col-2"><button iservice-id="Save" class="btn btn-primary">Save</button></div>
         <div class="col-10">
            <!--v-if-->
         </div>
      </div>
   </div>
</div>

  </div>
</div>
</template>